@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'MetricBold';
    src: local('MetricBold'), url(./assets/fonts/Metric-Bold.woff) format('woff');
}

@font-face {
    font-family: 'MetricLight';
    src: local('MetricLight'), url(./assets/fonts/Metric-Light.woff) format('woff');
}

@font-face {
    font-family: 'MetricRegular';
    src: local('MetricRegular'), url(./assets/fonts/Metric-Regular.woff) format('woff');
}

@font-face {
    font-family: 'MetricThin';
    src: local('MetricThin'), url(./assets/fonts/Metric-Thin.woff) format('woff');
}

@font-face {
    font-family: 'MetricSemiBold';
    src: local('MetricSemiBold'), url(./assets/fonts/Metric-SemiBold.woff) format('woff');
}

/* Change autofill color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #F6F6F6 inset !important;
}

input {
    font-family: 'MetricSemiBold' !important;
    color: #545454 !important;
}

html {
    font-family: 'MetricRegular';
}

/* For custom css properties in basic css files e.g KDatePicker */
:root {
    --darkPurple: #7D0FBE;
    --lightPurple: #a761d3;
    --purple: #AF52DE;
    --mainGreen: #238823;
    --main: #7D0FBE;
    --lightGreen2: #3dcc4a;
    --lightGreen: #00FF47;
    --lightGrey: #f1f1f1;
}